import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ButtonGroup } from "react-bootstrap";
import { Card } from "react-bootstrap";
import {
  useAppDispatch,
  useAppSelector,
  selectTsOrder,
  selectOrangePayInfo,
  selectUseDefaultValues,
} from "../../store/hooks";
import {
  setOrangePayInfo,
  toggleUseDefaultValues,
  submitOrangePayProps,
  OrangePayInfo,
} from "../../store/userSlice";

const OrangePayDevForm = () => {
  const tsOrder = useAppSelector(selectTsOrder);
  const useDefaultValues = useAppSelector(selectUseDefaultValues);
  const dispatch = useAppDispatch();
  const orangePayInfo = useAppSelector(selectOrangePayInfo);
  const [orangePayValues, setOrangePayValues] = useState(orangePayInfo);

  const defaultOrangePayInfo: OrangePayInfo = {
    storeNumber: "9749",
    storeZipCode: "30080",
    amount: 3400.0,
    orderNumber: "H9749-16200",
    billingAddress: {
      addressLine1: "2455 Paces Ferry Rd",
      addressLine2: "Building D",
      city: "Atlanta",
      state: "GA",
      zipCode: "30339",
    },
    uri: "" // TODO: who even knows what this was supposed to be now
  };

  const orangePayInfoFromOrder: OrangePayInfo = {
    storeNumber: tsOrder!.salesLocation!.hdStoreNumberC.toString(),
    storeZipCode: tsOrder!.salesLocation!.billingPostalCode.toString(),
    amount: tsOrder!.customerTotalC,
    orderNumber: tsOrder!.thdComOrderNumberC,
    billingAddress: {
      addressLine1: tsOrder!.billingStreet,
      addressLine2: "",
      city: tsOrder!.billingCity,
      state: tsOrder!.billingState,
      zipCode: tsOrder!.billingPostalCode.toString(),
    },
    uri: "" // see above
  };

  useEffect(() => {
    if (useDefaultValues === false) {
      dispatch(setOrangePayInfo(orangePayInfoFromOrder));
      setOrangePayValues(orangePayInfoFromOrder);
    } else if (useDefaultValues === true) {
      dispatch(setOrangePayInfo(defaultOrangePayInfo));
      setOrangePayValues(defaultOrangePayInfo);
    }
  }, [useDefaultValues]);

  const handleChange = (e: any) => {
    setOrangePayValues({
      ...orangePayValues,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleAddressChange = (e: any) => {
    setOrangePayValues({
      ...orangePayValues,
      billingAddress: {
        ...orangePayValues.billingAddress,
        [e.target.name]: e.target.value.trim(),
      },
    });
  };

  const handleToggleDefaultData = (e: any) => {
    e.preventDefault();
    if (useDefaultValues === false) {
      dispatch(toggleUseDefaultValues(true));
    } else if (useDefaultValues === true) {
      dispatch(toggleUseDefaultValues(false));
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(setOrangePayInfo(orangePayValues));
    dispatch(submitOrangePayProps(true));
  };

  return (
    <Card>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3" controlId="storeNumber">
            <Form.Label>storeNumber</Form.Label>
            <Form.Control
              name="storeNumber"
              onChange={handleChange}
              type="text"
              // value={orangePayValues.storeNumber}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="storeZipCode">
            <Form.Label>storeZipCode</Form.Label>
            <Form.Control
              name="storeZipCode"
              onChange={handleChange}
              type="text"
              value={orangePayValues.storeZipCode}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="amount">
            <Form.Label>amount</Form.Label>
            <Form.Control
              name="amount"
              onChange={handleChange}
              type="number"
              value={orangePayValues.amount}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="orderNumber">
            <Form.Label>orderNumber</Form.Label>
            <Form.Control
              name="orderNumber"
              onChange={handleChange}
              type="text"
              value={orangePayValues.orderNumber}
            />
          </Form.Group>

          <h4> Billing Address</h4>
          <Form.Group className="mb-3" controlId="addressLine1">
            <Form.Label>addressLine1</Form.Label>
            <Form.Control
              name="addressLine1"
              onChange={handleAddressChange}
              type="text"
              value={orangePayValues.billingAddress.addressLine1}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="addressLine2">
            <Form.Label>addressLine2</Form.Label>
            <Form.Control
              name="addressLine2"
              onChange={handleAddressChange}
              type="text"
              value={orangePayValues.billingAddress.addressLine2}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="city">
            <Form.Label>city</Form.Label>
            <Form.Control
              name="city"
              onChange={handleAddressChange}
              type="text"
              value={orangePayValues.billingAddress.city}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="state">
            <Form.Label>state</Form.Label>
            <Form.Control
              name="state"
              onChange={handleAddressChange}
              type="text"
              value={orangePayValues.billingAddress.state}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="zipCode">
            <Form.Label>zipCode</Form.Label>
            <Form.Control
              name="zipCode"
              onChange={handleAddressChange}
              type="text"
              value={orangePayValues.billingAddress.zipCode}
            />
          </Form.Group>
          <ButtonGroup className="mb-2">
            <Button
              variant="retail-primary"
              size="lg"
              onClick={handleToggleDefaultData}
            >
              {useDefaultValues
                ? "Use Data From Order"
                : "Use Default Test Data"}
            </Button>
            <Button variant="hd-primary" size="lg" onClick={handleSubmit}>
              Submit
            </Button>
          </ButtonGroup>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default OrangePayDevForm;

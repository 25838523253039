import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";

const SubmittingModal = (props: any) => {

  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      mb={3}
    >
      <Modal.Body>
        <div className="spinner-container">
          <h3 style={{ color: "hd-primary" }}>Submitting Payment </h3>
        </div>
        <div className="spinner-container">
          <Spinner animation="border" variant="hd-primary" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubmittingModal;

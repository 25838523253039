import {
  useAppSelector,
  selectTsOrder,
  selectOrderLineItems,
} from "../../store/hooks";
import Table from "react-bootstrap/Table";
import "../../styling/custom.scss";

const OrderDisplayTable = () => {
  const tsOrder = useAppSelector(selectTsOrder);
  const orderLineItems = useAppSelector(selectOrderLineItems);
  const columnHeadings = [
    { heading: "Description", property: "itemDescription" },
    { heading: "Qty", property: "quantity" },
    { heading: "Price", property: "price" },
  ];
  const propertyAsKey = "itemDescription";
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // const sumPrices = (arr: LineItem[]):number => {
  //   return arr.map(item => item.price).reduce((prev, curr) => prev + curr, 0);
  // }

  const discount = tsOrder!.kbMaxPromoAmountC;

  const showDiscount = (discount: string) => {
    if (parseFloat(discount) === 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            {columnHeadings.map((col) => (
              <th key={`header-${col.heading}`}>{col.heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {orderLineItems!.map((item) => (
            <tr key={`${item[propertyAsKey].toString()}-row`}>
              {columnHeadings.map((col) => (
                <td key={`${item[propertyAsKey].toString()}-${col.property}`}>
                  {col.property === "price"
                    ? formatter.format(item[col.property])
                    : item[col.property]}
                </td>
              ))}
            </tr>
          ))}
          {showDiscount(discount) ? (
            <tr>
              <th scope="row">
                <b>Discount</b>
              </th>
              <td></td>
              <td>{`-${formatter.format(parseFloat(discount))}`}</td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </>
  );
};

export default OrderDisplayTable;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "createOpCard", {
  enumerable: true,
  get: function get() {
    return _component.createOpCard;
  }
});
Object.defineProperty(exports, "sendRemoteAuth", {
  enumerable: true,
  get: function get() {
    return _component.sendRemoteAuth;
  }
});
Object.defineProperty(exports, "updateSaveCardFlag", {
  enumerable: true,
  get: function get() {
    return _component.updateSaveCardFlag;
  }
});
var _component = require("./component");
import { useAppSelector, selectPersistedOrderData } from "../../store/hooks";
import { Card } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const PartialPaymentDisplay = () => {
  const persistedOrderData = useAppSelector(selectPersistedOrderData);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const partialPayment = Number.parseFloat(persistedOrderData?.totalAmount ?? '0');
  const tax = formatter.format(0);
  const formattedPartialPayment = formatter.format(partialPayment!);

  return (
    <Card className="mb-2">
      <Card.Header as="h3">Partial Payment</Card.Header>
      <Card.Body>
        <Card.Text>
          <div className="partialPayDisclaimer">
            <p>
              Please note that because your delivery address is located in a
              state that regulates payment in full at the time of purchase, your
              order will not be paid in full by submitting today’s payment.
            </p>

            <p>
              You are responsible for the entire purchase amount and subsequent
              payments will be due at times and amounts as permitted by the laws
              in your state. You will receive an email from Tuff Shed confirming
              your order, but you will need to visit homedepot.com to view your
              receipt. Click{" "}
              <a
                href="https://www.888tuffshed.com/tuff-shed-invoice-from-homedepot-com/"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{" "}
              to see instructions for viewing your balance due at homedepot.com.
            </p>
          </div>
        </Card.Text>
        <Table size="sm">
          <thead>
            <tr>
              <td>Partial Payment</td>
              <td>{formattedPartialPayment}</td>
            </tr>
            <tr>
              <td>Tax</td>
              <td>{tax}</td>
            </tr>
          </thead>
          <tbody>
            <tr className="table-success">
              <td>
                <b>Due Today</b>
              </td>
              <td>
                <b>{formattedPartialPayment}</b>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
  // }
};

export default PartialPaymentDisplay;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface Theme {
  themeName: string;
  backgroundColor?: string;
  textColor?: string;
  primary?: string;
  bootstrapTheme?: string;
  path?: string;
}

export const retailTheme: Theme = {
  themeName: "Retail",
  backgroundColor: "#f5f5f5",
  textColor: "#ffffff",
  primary: "#981B1E",
  bootstrapTheme: "retail-primary",
  path: "/retail"
}

export const hdTheme: Theme = {
  themeName: "Home Depot",
  backgroundColor: "#f4f3ef",
  
  textColor: "#ffffff",
  primary: "#ED7124",
  bootstrapTheme: "hd-primary",
  path: "/hd"
}

//Defining our initialState's type
type initialStateType = {
  theme: Theme;
};

const initialState: initialStateType = {
  theme: hdTheme,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    applyTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
  },
});

export default themeSlice.reducer;
export const selectTheme = (state: RootState) => state.theme.theme;

export const { applyTheme } = themeSlice.actions;

import React, { useEffect } from "react";

const ExternalRedirect = () => {
  const homeLink = process.env.REACT_APP_SALES_CHANNEL_HOME;

  useEffect(() => {
    window.location.href = `${homeLink}`;
  }, []);

  return <></>;
};

export default ExternalRedirect;

import { createOpCard, sendRemoteAuth } from "orangepay-ecommerce-sdk";
import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { ORANGE_PAY_URL } from "../..";
import { LogLevel } from "../../models/enums/logLevel";
import LogEventSliceModel from "../../models/logEventSliceModel";
import {
    selectOrangePayError,
    selectOrangePayInfo,
    selectPaymentFormLoaded,
    selectThdAuthToken,
    selectThdOrderValid,
    selectTsAccessToken,
    selectTsOrder,
    useAppDispatch,
    useAppSelector,
} from "../../store/hooks";
import { setNotification } from "../../store/notifierSlice";
import { logEvent, setCreditCardResponseData } from "../../store/orderSlice";
import { setPaymentFormLoaded, setPaymentFormSubmitStatus, setPaymentFormSubmitted } from "../../store/userSlice";
import SubmittingModal from "../modals/SubmittingModal";

export const PaymentForm = () => {
    const dispatch = useAppDispatch();
    const paymentFormLoaded = useAppSelector(selectPaymentFormLoaded);
    const tsAccessToken = useAppSelector(selectTsAccessToken);
    const thdAuthToken = useAppSelector(selectThdAuthToken);
    const thdAccessToken = thdAuthToken!.access_token;
    const orangePayInfo = useAppSelector(selectOrangePayInfo);
    const orangePayError = useAppSelector(selectOrangePayError);
    const tsOrder = useAppSelector(selectTsOrder);

    const thdOrderValid = useAppSelector(selectThdOrderValid);
    const environment = process.env.REACT_APP_ENVIRONMENT;

    const [modalShow, setModalShow] = React.useState(false);
    const [buttonShow, setButtonShow] = React.useState(false);

    const mockCCResponse = {
        sessionId: "598d4423-a375-43e7-bb73-506cb99fc128",
        orderNumber: "H9749-16459",
        credit: {
            posEntryMode: "KEYED",
            uuid: "598d4423-a375-43e7-bb73-506cb99fc128-1",
            amountRequested: 3419,
            purchaseOrderRequired: false,
            authorizationDetails: {
                authorizationIdentificationResponse: "OK4755",
                authorizationStatus: "APPROVED",
                avsResponseCode: "Y",
                ccv2ResponseCode: "M",
                auth_uuid: "212513609651545905",
                hostResponseText: "APPROVED",
                cardProcessorID: "BA",
                amountAuthorized: 3419,
            },
            cardDetails: {
                reportPaymentMethodCode: "VI",
                paymentMethodCode: "VI",
                cardExpirationDate: "1224",
                accountNumberXref: "4012000000000412",
                authorizedBuyerID: "",
                buyerName: "",
                isGSA: false,
                isPrepaid: false,
            },
        },
        autoReversal: false,
    };

    const sendTransaction = () => {
        const billingDetails = {
            addressLine1: tsOrder!.personAccount.billingStreet,
            addressLine2: "",
            city: tsOrder!.personAccount.billingCity,
            state: tsOrder!.personAccount.billingState,
            zipCode: tsOrder!.personAccount.billingPostalCode,
        };
        sendRemoteAuth(billingDetails);
    };

    const handleFormSubmit = async () => {
        console.log("form submitted");
        // setButtonShow(false)
        dispatch(setPaymentFormSubmitted(true));
        dispatch(setPaymentFormSubmitStatus("loading"));
        setModalShow(true);
        sendTransaction();
    };

    useEffect(() => {
        //add a cleanup function to remove this dom element
        if (paymentFormLoaded === false) {
            createOpCard({
                env: orangePayInfo.env,
                clientId: orangePayInfo.clientId,
                clientToken: thdAccessToken!,
                url: ORANGE_PAY_URL,
                storeNumber: orangePayInfo.storeNumber,
                storeZipCode: orangePayInfo.storeZipCode,
                amount: orangePayInfo.amount,
                countryCode: orangePayInfo.countryCode,
                languageCode: orangePayInfo.languageCode,
                orderNumber: orangePayInfo.orderNumber,
                saqAEnabled: true,
                transactionOptions: {
                    preAuthorize: orangePayInfo.transactionOptions!.preAuthorize,
                },
                billingAddress: {
                    addressLine1: tsOrder?.personAccount.billingStreet,
                    addressLine2: "",
                    city: tsOrder?.personAccount.billingCity,
                    state: tsOrder?.personAccount.billingState,
                    zipCode: tsOrder?.personAccount.billingPostalCode,
                },
                cssStyles: ".flex-container { border: 1px solid rgba(0, 0, 0, 0.125); border-radius: 0.5rem;}",
                info: (response: any) => {
                    console.log(response);

                    if (response.reasonCode === "2000") {
                        console.log("loaded the form SuccessFully");
                        dispatch(setPaymentFormLoaded(true));
                    }

                    //COMMENT OUT BEFORE PUSHING
                    /*
          else if (environment === "dev" && response.reasonCode === "5020") {
            //if we are in local development, fake a successful response from orange pay
            //using the mock credit card data
            console.log("environment", environment);
            console.log("FAKING A SUCCESS");
            setModalShow(false)
            //for mocking a successfull credit card transaction
            dispatch(setCreditCardResponseData(mockCCResponse));
            dispatch(setPaymentFormSubmitStatus("succeeded"));
          }*/
                    else if (parseInt(response.reasonCode) >= 3053 && parseInt(response.reasonCode) <= 5086) {
                        setModalShow(false); //change name to submitting modal or something
                        dispatch(setPaymentFormSubmitStatus("idle"));
                        dispatch(setPaymentFormSubmitted(false));
                        const messageHeader = response.description;
                        const messageBody = "Your payment cannot be processed";
                        const logEventSliceModel: LogEventSliceModel = {
                            logLevel: LogLevel.Error,
                            apiToken: tsAccessToken?.accessToken ?? "",
                            message: `${orangePayInfo.orderNumber} - ${messageBody}`,
                            detail: response.description,
                        };
                        dispatch(logEvent(logEventSliceModel));
                        dispatch(setNotification({ messageBody, messageHeader }));
                    } else {
                        console.log("Orange Pay Form Load Response", response);
                    }
                },
                success: (response: any) => {
                    //this only runs if the credit card is successfully processed
                    console.log("success?");

                    dispatch(setCreditCardResponseData(response));
                    dispatch(setPaymentFormSubmitStatus("succeeded"));
                    setModalShow(false);
                },
            }).render("#iFrameContainer");
            console.log("orangePayInfo", orangePayInfo);
        }
    }, []);

    if (
        paymentFormLoaded === true &&
        //change to persisted order data
        thdOrderValid.isValid === true &&
        orangePayError === null
    ) {
        return (
            <>
                <Button
                    // style={{ display: validate()
                    // ? "block" : "none"}}
                    variant="hd-primary"
                    size="lg"
                    className="btn-checkout"
                    onClick={handleFormSubmit}
                    disabled={buttonShow}>
                    Submit Payment
                </Button>
                <SubmittingModal show={modalShow} onHide={() => setModalShow(false)} />
            </>
        );
    } else {
        return <></>;
    }
};

//Any global style that we want to be transmitted through the styled components style provider which makes them available to all components as props automatically 

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props: any) => props.theme.backgroundColor};
  }
`;

export default GlobalStyle;

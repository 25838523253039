"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOpCard = void 0;
exports.sendRemoteAuth = sendRemoteAuth;
exports.updateSaveCardFlag = updateSaveCardFlag;
var _postRobot = _interopRequireDefault(require("post-robot"));
var _zoid = require("zoid/dist/zoid.frameworks");
var _util = require("./util");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function sendViaPostRobot(flag, flagString) {
  var iframeName = _toConsumableArray(document.getElementsByTagName('iframe')).find(function (elementInIframesArray) {
    var _elementInIframesArra;
    return (_elementInIframesArra = elementInIframesArray.name) === null || _elementInIframesArra === void 0 ? void 0 : _elementInIframesArra.includes('zoid__card_sdk');
  }).name;
  _postRobot.default.send(window.frames[iframeName], flagString, flag);
}
function updateSaveCardFlag(saveCard) {
  sendViaPostRobot(saveCard, 'updateSaveCardFlag');
}
function sendRemoteAuth(billingInfo) {
  console.log('Sending remote auth');
  sendViaPostRobot(billingInfo, 'sendAuth');
}
var createOpCard = (0, _zoid.create)({
  tag: 'card-sdk',
  dimensions: {
    width: '100%',
    height: '215px'
  },
  url: function url(_ref) {
    var props = _ref.props;
    return props.url;
  },
  prerenderTemplate: function containerTemplate(_ref2) {
    var doc = _ref2.doc;
    return (0, _util.createLoadingSpinner)(doc);
  },
  props: {
    url: {
      type: 'string',
      required: true
    },
    clientId: {
      type: 'string',
      required: true
    },
    clientToken: {
      type: 'string',
      required: true
    },
    storeNumber: {
      type: 'string',
      required: true
    },
    storeZipCode: {
      type: 'string',
      required: true
    },
    amount: {
      type: 'number',
      required: true
    },
    countryCode: {
      type: 'string',
      required: false,
      default: function _default() {
        return 'US';
      }
    },
    languageCode: {
      type: 'string',
      required: false,
      default: function _default() {
        return 'en-US';
      }
    },
    orderNumber: {
      type: 'string',
      required: true
    },
    transactionOptions: {
      type: 'object',
      required: false,
      default: function _default() {
        return {
          preAuthorize: false,
          tokenizeOnly: false
        };
      }
    },
    billingAddress: {
      type: 'object',
      required: false
    },
    paymentId: {
      type: 'string',
      required: false
    },
    svocAccountId: {
      type: 'string',
      required: false
    },
    svocUserId: {
      type: 'string',
      required: false
    },
    cssStyles: {
      type: 'string',
      required: false
    },
    saveCard: {
      type: 'boolean',
      required: false,
      default: function _default() {
        return false;
      }
    },
    cardBrokerEnabled: {
      type: 'boolean',
      required: false,
      default: function _default() {
        return true;
      }
    },
    saqAEnabled: {
      type: 'boolean',
      required: false,
      default: function _default() {
        return false;
      }
    },
    rsaEnabled: {
      type: 'boolean',
      required: false,
      default: function _default() {
        return false;
      }
    },
    cvvCaptureEnabled: {
      type: 'boolean',
      required: false,
      default: function _default() {
        return false;
      }
    },
    offlineSupportEnabled: {
      type: 'boolean',
      required: false,
      default: function _default() {
        return false;
      }
    },
    sdkVersion: {
      type: 'string',
      required: false,
      default: function _default() {
        return require('../../package.json').version;
      }
    },
    info: {
      type: 'function',
      required: true
    },
    success: {
      type: 'function',
      required: true
    }
  },
  // autoresize card component to make it responsive
  autoResize: {
    width: false,
    height: true,
    element: '#root'
  }
});
exports.createOpCard = createOpCard;
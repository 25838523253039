import React, { useEffect } from "react";
import PaymentFormWrapper from "./PaymentFormWrapper";
import OrangePayDevForm from "../forms/OrangePayDevForm";
import {
  useAppSelector,
  selectUser,
  selectOrangePayInfo,
  selectIsOrangePayPropsSubmitted,
} from "../../store/hooks";

const OrangePayDevWrapper = () => {
  const isDev = useAppSelector(selectUser).isDev;
  const orangePayInfo = useAppSelector(selectOrangePayInfo);
  const isOrangePayPropsSubmitted = useAppSelector(
    selectIsOrangePayPropsSubmitted
  );

  useEffect(() => {}, [orangePayInfo, isOrangePayPropsSubmitted]);

  return (
    <div>
      {isDev === true && isOrangePayPropsSubmitted === false ? (
        <OrangePayDevForm />
      ) : (
        <PaymentFormWrapper />
      )}
    </div>
  );
};

export default OrangePayDevWrapper;

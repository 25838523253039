import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import LogEventModel from "../models/logEventModel";
import LogEventSliceModel from "../models/logEventSliceModel";

const apiBaseUrl = process.env.REACT_APP_SERVICES_BASE_URL;
const getOrderByIdUrl = process.env.REACT_APP_ORDER_SERVICE_URL;
const postSaleRecallUrl = process.env.REACT_APP_POST_SALE_RECALL_SERVICE_URL;
const thdOrderServiceUrl = process.env.REACT_APP_THD_ORDER_SERVICE_URL;
const thdOrderServiceLogEventUrl = process.env.REACT_APP_LOG_EVENT_URL;

const persistedOrderUrl = process.env.REACT_APP_PERSISTED_ORDER_URL;

export interface Order {
    tsOrderStatus: string;
    urlId: string | null;
    error?: string | null;
    tsOrder?: TsOrder;
    products?: Product[];
    lineItems?: LineItem[];
    tsOrderIsValid: ValidCheck;
    thdOrderIsValid: ValidCheck;
    creditCardResponseData?: creditCardResponseData;
    creditCardResponseStatus: boolean;
    thdOrderStatus: string;
    thdOrder?: ThdOrder;
    postSaleRecallRequestStatus: string;
    postSaleRecallRequestData?: PostSaleRecallRequestData;
    postSaleRecallResponse?: PostSaleRecallResponseData;
    orangePayError?: string | null;
    creditCardTransactionSuccess: boolean | null;
    persistedOrderResponse?: PersistedOrderResponse;
    persistedOrderStatus: string;
    isPartialPayment: boolean;
}
export interface ValidCheck {
    isValid: boolean | null;
    reason: string | null;
}

export interface creditCardResponseData {
    sessionId: string;
    orderNumber: string;
    credit: {
        posEntryMode: string;
        uuid: string;
        amountRequested: number | null;
        purchaseOrderRequired: boolean | null;
        authorizationDetails: {
            authorizationIdentificationResponse: string;
            authorizationStatus: string;
            avsResponseCode: string;
            ccv2ResponseCode: string;
            auth_uuid: string;
            hostResponseText: string;
            cardProcessorID: string;
            amountAuthorized: number | null;
        };
        cardDetails: {
            reportPaymentMethodCode: string;
            paymentMethodCode: string;
            cardExpirationDate: string;
            accountNumberXref: string;
            authorizedBuyerID: string;
            buyerName: string;
            isGSA: boolean | null;
            isPrepaid: boolean | null;
        };
    };
    autoReversal: boolean | null;
}

export interface LineItem {
    [key: string]: string | number;
    itemDescription: string;
    quantity: number;
    price: number;
    discount: number;
}

export interface TsOrder {
    id: string;
    name: string | null;
    accountId: string;
    activatedById: string;
    billingCity: string;
    billingCountry: string;
    billingCountryCode: string;
    billingGeocodeAccuracy: string | number | null;
    billingLatitude: number | null;
    billingLongitude: number | null;
    billingPostalCode: number;
    billingState: string;
    billingStateCode: string;
    billingStreet: string;
    buildingTypeC: string | null;
    billToContactId: string | null;
    companyAuthorizedById: string | null;
    customerTotalC: number;
    description: string | null;
    estimatedTaxC: string | null;
    factoryStoreC: string;
    isReductionOrder: boolean;
    items?: Item[];
    kbMaxPromoAmountC: string;
    opportunityId: string;
    opportunity: null;
    orderListAmountC: number;
    orderNumber: number;
    orderReferenceNumber: number | string | null;
    originalOrderId: string | null;
    personAccount: PersonAccount;
    poNumber: number | string | null;
    pricebook2Id: string;
    quoteId: string;
    quote: null;
    recordTypeId: string;
    sbqqQuoteC: null;
    salesLocationC: string;
    salesLocation?: SalesLocation;
    shippingCity: string;
    shippingCountry: string;
    shippingCountryCode: string;
    shippingGeocodeAccuracy: number | string | null;
    shippingLatitude: number | null;
    shippingLongitude: number | null;
    shippingPostalCode: number;
    shippingState: string;
    shippingStateCode: string;
    shippingStreet: string;
    shipToContactId: number | string | null;
    tsOrderStatus: string;
    statusCode: string;
    thdComOrderNumberC: string;
    thdOrderNumberC: string;
    totalAmount: number;
    totalDiscountAmountC: number;
    type: string;
    fields?: Field;
    [x: string]: any;
}

export interface Item {
    additionalDiscountC: 0;
    availableQuantity: 0;
    childOrderItems: Item[];
    customDescriptionC: string;
    description: string;
    durationC: 0;
    isShedConfigurationC: true;
    id: string;
    listPrice: 0;
    manufacturingOffsetC: 0;
    name: string;
    nonStandardC: true;
    nonStockC: true;
    nonStockNotesC: string;
    nonStockOrderedC: true;
    nonStockReceivedC: true;
    opStatusC: string;
    orderId: string;
    orderItemNumber: string;
    originalOrderItemId: string;
    pricebookEntryId: string;
    productTypeC: string;
    product2Id: string;
    quantity: 0;
    sbcfIsNonStandardC: true;
    sbcfIsNonStockC: true;
    sbqqActivatedC: true;
    sbqqAssetC: string;
    sbqqBillingFrequencyC: string;
    sbqqBillingTypeC: string;
    sbqqBlockPriceC: string;
    sbqqBookingsIndicatorC: string;
    sbqqBundleRootC: string;
    sbqqChargeTypeC: string;
    sbqqContractC: string;
    sbqqContractActionC: string;
    sbqqContractedC: true;
    sbqqContractingMethodC: string;
    sbqqDefaultSubscriptionTermC: 0;
    sbqqDimensionTypeC: string;
    sbqqDiscountScheduleC: string;
    sbqqOrderedQuantityC: 0;
    sbqqOrderProductBookingsC: 0;
    sbqqPriceDimensionC: string;
    sbqqPriceScheduleC: string;
    sbqqPricingMethodC: string;
    sbqqProductSubscriptionTypeC: string;
    sbqqProrateMultiplierC: 0;
    sbqqQuotedListPriceC: 0;
    sbqqQuotedQuantityC: 0;
    sbqqQuoteLineC: string;
    sbqqRequiredByC: string;
    sbqqRevisedOrderProductC: string;
    sbqqSegmentIndexC: 0;
    sbqqSegmentKeyC: string;
    sbqqShippingAccountC: string;
    sbqqStatusC: string;
    sbqqSubscriptionC: string;
    sbqqSubscriptionPricingC: string;
    sbqqSubscriptionTermC: 0;
    sbqqSubscriptionTypeC: string;
    sbqqTaxAmountC: 0;
    sbqqTaxCodeC: string;
    sbqqTermDiscountScheduleC: string;
    sbqqTotalAmountC: 0;
    sbqqUnproratedNetPriceC: 0;
    sbqqUpgradedSubscriptionC: string;
    selfScheduleEligibleC: true;
    totalPrice: 0;
    tuffCustomerPriceC: 0;
    tuffCustomerTotalC: 0;
    tuffListPriceC: 0;
    tuffListTotalC: 0;
    unitPrice: 0;
    wholesalePriceBeforePromotionsC: 0;
    wholesaleTotalBeforePromotionsC: 0;
}
//      sbqqTerminatedDateC: 2022-01-07T13:15:13.534Z; this data can't be serialized just yet

export interface PersonAccount {
    areaSalesManagerC: string;
    billingCity: string;
    billingCountry: string;
    billingCountryCode: string;
    billingGeocodeAccuracy: string;
    billingLatitude: 0;
    billingLongitude: 0;
    billingPostalCode: string;
    billingState: string;
    billingStateCode: string;
    billingStreet: string;
    buTypeC: string;
    crossDockC: string;
    description: string;
    geocodeAccuracyC: string;
    geocodeQualityC: string;
    geolocationLatitudeS: 0;
    geolocationLongitudeS: 0;
    gmRetailApproverNameC: string;
    hdLicenseNumberSC: string;
    hdStoreNumberC: string;
    hdStoreRetailSalesCenterC: string;
    homeDepotOpportunityOwnerC: string;
    id: string;
    industry: string;
    isCustomerPortal: true;
    latitudeSalesforceMapsC: 0;
    leadQueueC: string;
    leadRouteShapeC: string;
    locationHoursC: string;
    longitudeSalesforceMapsC: 0;
    mapsAssignmentRuleC: string;
    mapsShapeLayerC: string;
    mtRegionCC: string;
    name: string;
    nextgenC: true;
    ownership: string;
    parentId: string;
    personEmail: string;
    phone: string;
    photoUrl: string;
    recordTypeId: string;
    retailOpportunityOwnerC: string;
    sataboverMergeActionC: string;
    sbcfNewGmRetailApproverC: string;
    sbqqAssetQuantitiesCombinedC: true;
    sbqqContractCoTerminationC: string;
    sbqqCoTermedContractsCombinedC: true;
    sbqqCoTerminationEventC: string;
    sbqqDefaultOpportunityC: string;
    sbqqIgnoreParentContractedPricesC: true;
    sbqqPreserveBundleC: true;
    sbqqRenewalModelC: string;
    sbqqRenewalPricingMethodC: string;
    sbqqTaxExemptC: string;
    sfdcWholesaleGmApproverC: string;
    thdDistrictC: 0;
    thdMarketC: string;
    thdRegionC: string;
    tsFactoryC: string;
    tsRegionC: string;
    type: string;
}

export interface Product {
    [key: string]: string | number;
    product: string;
    quantity: number;
    price: number;
}

export interface SalesLocation {
    areaSalesManagerC: string;
    billingCity: string;
    billingCountry: string;
    billingCountryCode: string;
    billingGeocodeAccuracy: string;
    billingLatitude: number;
    billingLongitude: number;
    billingPostalCode: number;
    billingState: string;
    billingStateCode: string;
    billingStreet: string;
    buTypeC: string | null;
    crossDockC: number;
    description: string | null;
    geocodeAccuracyC: string;
    geocodeQualityC: string;
    geolocationLatitudeS: number;
    geolocationLongitudeS: number;
    gmRetailApproverNameC: string;
    hdLicenseNumberSC: null | number | string;
    hdStoreNumberC: number;
    hdStoreRetailSalesCenterC: number;
    homeDepotOpportunityOwnerC: string | null;
    id: string;
    industry: null;
    isCustomerPortal: boolean;
    latitudeSalesforceMapsC: number;
    leadQueueC: null;
    leadRouteShapeC: string;
    locationHoursC: null;
    longitudeSalesforceMapsC: number;
    mapsAssignmentRuleC: string | null;
    mapsShapeLayerC: string;
    mtRegionCC: string;
    name: string;
    nextgenC: boolean;
    ownership: string | null;
    parentId: string;
    personEmail: string | null;
    phone: string;
    photoUrl: string;
    recordTypeId: string;
    retailOpportunityOwnerC: string | null;
    sataboverMergeActionC: string;
    sbcfNewGmRetailApproverC: string;
    sbqqAssetQuantitiesCombinedC: boolean;
    sbqqContractCoTerminationC: null;
    sbqqCoTermedContractsCombinedC: boolean;
    sbqqCoTerminationEventC: null;
    sbqqDefaultOpportunityC: null;
    sbqqIgnoreParentContractedPricesC: boolean;
    sbqqPreserveBundleC: boolean;
    sbqqRenewalModelC: null;
    sbqqRenewalPricingMethodC: null;
    sbqqTaxExemptC: null;
    sfdcWholesaleGmApproverC: boolean;
    thdDistrictC: number;
    thdMarketC: null;
    thdRegionC: string;
    tsFactoryC: string;
    tsRegionC: string;
    type: string;
}

//requires mapped types
export interface Field {
    [key: string]: string | boolean | number;
}

export interface PostSaleRecallRequestData {
    retailStoreId: string;
    customerAddressCity?: string;
    customerAddressState?: string;
    customerAddressPostalCode?: string;
    customerNameEmail: string;
    lineItemAmount: string;
    lineItemAuthUuid: string;
    authorizationCode: string;
    authorizationDateTime?: string;
    referenceNumber: string;
    poNumber: string;
    cardProcessorId: string;
    cardType: string;
    tenderType: string;
    recallId?: string;
    expirationDate: string;
    comOrderNumber: string | null;
    systemSourceUserId: string;
    entryMethod: string;
    typeCode: string;
    salesforceOrderId: string;
    xrefNumber: string;
    paymentMethodCode: string;
    authorizationStatus: string;
}

export interface PostSaleRecallResponseData {
    uuid: string;
    responseCode: string;
    tandemResponseCode: string;
    tandemResponseMessage: string;
    retryable: boolean;
    data: {
        // transaction: {
        //   retailStoreID: string;
        //   workstationID: string;
        //   sequenceNumber: string;
        //   businessDayDate: string;
        // }
    };
}

export interface ThdOrderValidatedObject {
    storeNumber: string;
    configuratorCode: string;
    userId: string;
    languageCode: string;
    transactOrderNumber: string;
    orderRetailAmount: number;
    taxAmount: number;
    minPayment: number;
    measureQuote: boolean;
    customer: ThdOrderCustomer;
    serviceItems: ThdOrderServiceItem[];
}

export interface ThdOrderCustomer {
    firstName: string;
    lastName: string;
    email: string;
    address: ThdOrderAddress;
    phoneList: any[];
}

export interface ThdOrderAddress {
    street: string;
    street2: string;
    city: string;
    county: null;
    state: string;
    country: string;
    postalCode: string;
}

export interface ThdOrderServiceItem {
    sku: number;
    mvendorNumber: number;
    instlUoiCd: number;
    installProgramType: string;
    installQuantity: number;
    furnishInstallFlag: boolean;
    installDeliveryAvailable: boolean;
    retail: number;
    cost: number;
    quantity: number;
    optionId: number;
    specialInstructions: string;
    cstmInstlItems: any[];
    fulfillment: ThdOrderFulfillment;
    crtProcessCd: number;
    measureCreditAmount: number;
    discountAmount: number;
    leadDays: number;
    soDirShipFlg: boolean;
    classNumber: number;
    subClassNumber: number;
    minRetlAmt: number;
}

export interface ThdOrderFulfillment {
    fulfillmentType: string;
    shipToHomeDetails: ShipToHomeDetails;
}

export interface ShipToHomeDetails {}

export interface ThdOrder {
    comOrderNumber: string | null;
    orderId: string;
    purchaseOrderNumber: string;
    storeNumber: string;
    taxAmount: string;
    subtotalAmount: string;
    totalAmount: string;
    stagedAmount: string;
    wasSuccessful: boolean;
    statusCode: 0;
    rawResponse?: string;
    statusMessage: string;
    parsedResponseResult: {
        isValid: boolean;
        messages: string[];
        validatedObject: ThdOrderValidatedObject;
    };
}

export interface PersistedOrderResponse {
    isPartialPayment: boolean;
    id: string;
    createdDateUtc: string;
    modifiedDateUtc: string;
    comOrderNumber: string;
    recallId: string;
    thdCustomerId: string;
    thdStoreId: string;
    orderRetailAmount: number;
    taxAmount: number;
    stagedAmount: number;
}

export const defaultCreditCardResponseData: creditCardResponseData = {
    sessionId: "",
    orderNumber: "",
    credit: {
        posEntryMode: "",
        uuid: "",
        amountRequested: null,
        purchaseOrderRequired: null,
        authorizationDetails: {
            authorizationIdentificationResponse: "",
            authorizationStatus: "",
            avsResponseCode: "",
            ccv2ResponseCode: "",
            auth_uuid: "",
            hostResponseText: "",
            cardProcessorID: "",
            amountAuthorized: null,
        },
        cardDetails: {
            reportPaymentMethodCode: "",
            paymentMethodCode: "",
            cardExpirationDate: "",
            accountNumberXref: "",
            authorizedBuyerID: "",
            buyerName: "",
            isGSA: null,
            isPrepaid: null,
        },
    },
    autoReversal: null,
};

export const defaultTsOrderIsValid: ValidCheck = {
    isValid: null,
    reason: null,
};

export const defaultHdOrderIsValid: ValidCheck = {
    isValid: null,
    reason: null,
};

export const defaultOrder: Order = {
    tsOrderStatus: "idle",
    urlId: null,
    error: null,
    creditCardTransactionSuccess: null,
    postSaleRecallRequestStatus: "idle",
    thdOrderStatus: "idle",
    creditCardResponseStatus: false,
    tsOrderIsValid: defaultTsOrderIsValid,
    thdOrderIsValid: defaultHdOrderIsValid,
    orangePayError: null,
    creditCardResponseData: defaultCreditCardResponseData,
    persistedOrderStatus: "idle",
    isPartialPayment: false,
};

//Defining our initialState's type
type initialStateType = {
    order: Order;
};

const initialState: initialStateType = {
    order: defaultOrder,
};

export type FetchValues = {
    AccessToken: string | null;
    urlParamId: string | null;
};

export type ThdFetchValues = {
    AccessToken: string | null | undefined;
    comOrderNumber: string | null;
};

export type PostSaleRecallFetchValues = {
    data: PostSaleRecallRequestData;
    apiAccessToken: string;
};

export const normalizeLineItems = (items: Item[]) => {
    return items.map((item) => ({
        itemDescription: item.customDescriptionC,
        quantity: item.sbqqQuotedQuantityC,
        price: item.tuffCustomerTotalC,
        discount: item.additionalDiscountC,
    }));
};

//we can chain these together in a promise.all but I think it is better to
//keep them seperate for many reasons
//can probably check validity here in promise chain

export const fetchTsOrder = createAsyncThunk("order/fetchTsOrder", async (values: FetchValues) => {
    const response = await axios.get<TsOrder>(`${apiBaseUrl}${getOrderByIdUrl}${values.urlParamId}/details?expand=items&expand=account&expand=saleslocation`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + values.AccessToken,
        },
    });
    console.log(response);
    const tsOrder: TsOrder = response.data;
    return tsOrder;
});

// create type for args {comOrderNumber, accesstoken}
//should we set timeout here? this call often takes a looong time to fail like around 1 minute
//best way to do so probably in an axios config
//can probably check validity here in promise chain
export const fetchThdOrder = createAsyncThunk("order/fetchThdOrder", async (thdfetchValues: ThdFetchValues) => {
    const response = await axios.get<ThdOrder>(`${apiBaseUrl}${thdOrderServiceUrl}${thdfetchValues.comOrderNumber}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + thdfetchValues.AccessToken,
        },
    });
    const originalResponse: ThdOrder = response.data;
    const { rawResponse, ...ThdOrderResponse } = originalResponse;
    return ThdOrderResponse;
});

///This step is to fetch the staged price and if it is a partial pay state or not
///Make sure to change the URL here before going to production ADD BASE URL BACK IN
export const fetchPersistedOrder = createAsyncThunk("order/fetchPersistedOrder", async (thdfetchValues: ThdFetchValues) => {
    const response = await axios.get<ThdOrder>(`${apiBaseUrl}${thdOrderServiceUrl}${thdfetchValues.comOrderNumber}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + thdfetchValues.AccessToken,
        },
    });
    const originalResponse: ThdOrder = response.data;
    const { rawResponse, ...ThdOrderResponse } = originalResponse;
    return ThdOrderResponse;
});

///change values and get access token from state rather than passed in
export const fetchPostSaleRecall = createAsyncThunk("order/fetchPostSaleRecall", async (values: PostSaleRecallFetchValues) => {
    console.log(typeof values.data);
    console.log(values.data);
    const response = await axios.post<PostSaleRecallResponseData>(`${apiBaseUrl}${postSaleRecallUrl}`, values.data, {
        headers: {
            "Content-Type": "application/json",
            accept: "text/plain",
            Authorization: "Bearer " + values.apiAccessToken,
        },
    });
    const postSaleRecallResponse: PostSaleRecallResponseData = response.data;
    console.log("postSaleRecallResponseresponse", postSaleRecallResponse);

    return postSaleRecallResponse;
});

export const logEvent = createAsyncThunk("order/logEvent", async (model: LogEventSliceModel) => {
    const logEventModel = model as LogEventModel;
    await axios.post<string>(`${apiBaseUrl}${thdOrderServiceLogEventUrl}`, logEventModel, {
        headers: {
            "Content-Type": "application/json",
            accept: "text/plain",
            Authorization: "Bearer " + model.apiToken,
        },
    });
});

const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        setUrlId: (state, action) => {
            const paramID = action.payload.id;
            state.order.urlId = paramID;
        },
        setCreditCardResponseData: (state, action) => {
            console.log("setCreditCardResponseData", action.payload);
            state.order.creditCardResponseData = action.payload;
            state.order.creditCardResponseStatus = true;
        },
        setCreditCardResponseStatus: (state, action) => {
            state.order.creditCardResponseStatus = action.payload;
        },
        setCreditCardTransactionSuccess: (state, action) => {
            state.order.creditCardTransactionSuccess = action.payload;
        },
        retryOrderFetch: (state, action) => {
            state.order.error = null;
            state.order.tsOrderStatus = "idle";
            state.order.thdOrderStatus = "idle";
        },
        setTsOrderIsValid: (state, action) => {
            state.order.tsOrderIsValid.isValid = action.payload.isValid;
            state.order.tsOrderIsValid.reason = action.payload.reason;
        },
        setThdOrderIsValid: (state, action) => {
            state.order.thdOrderIsValid.isValid = action.payload.isValid;
            state.order.thdOrderIsValid.reason = action.payload.reason;
        },
        setOrangePayError: (state, action) => {
            state.order.orangePayError = action.payload;
        },
        setIsPartialPay: (state, action) => {
            state.order.isPartialPayment = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTsOrder.pending, (state, action) => {
            state.order.tsOrderStatus = "loading";
        });
        builder.addCase(fetchTsOrder.fulfilled, (state, action) => {
            state.order.tsOrderStatus = "succeeded";
            const childOrderItems = action.payload.items![0].childOrderItems;
            state.order.tsOrder = action.payload;
            state.order.lineItems = normalizeLineItems(childOrderItems);
        });
        builder.addCase(fetchTsOrder.rejected, (state, action) => {
            console.error("Order Service Error", action.error);
            state.order.tsOrderStatus = "failed";
            state.order.error = action.error.message;
        });
        builder.addCase(fetchPostSaleRecall.pending, (state, action) => {
            state.order.postSaleRecallRequestStatus = "loading";
        });
        builder.addCase(fetchPostSaleRecall.fulfilled, (state, action) => {
            state.order.postSaleRecallRequestStatus = "succeeded";
            state.order.postSaleRecallResponse = action.payload;
        });
        builder.addCase(fetchPostSaleRecall.rejected, (state, action) => {
            console.log("postSaleRecallResponseresponse", action);

            state.order.postSaleRecallRequestStatus = "failed";
            state.order.error = action.error.message;
        });
        builder.addCase(fetchThdOrder.pending, (state, action) => {
            state.order.thdOrderStatus = "loading";
        });
        builder.addCase(fetchThdOrder.fulfilled, (state, action) => {
            state.order.thdOrderStatus = "succeeded";
            state.order.thdOrder = action.payload;
        });
        builder.addCase(fetchThdOrder.rejected, (state, action) => {
            console.error("ThdOrder Endpoint Error", action.error);
            state.order.thdOrderStatus = "failed";
            state.order.error = action.error.message;
        });

        builder.addCase(fetchPersistedOrder.pending, (state, action) => {
            state.order.persistedOrderStatus = "loading";
        });
        builder.addCase(fetchPersistedOrder.fulfilled, (state, action) => {
            state.order.persistedOrderStatus = "succeeded";
            state.order.thdOrder = action.payload;
            state.order.isPartialPayment = false;
        });
        builder.addCase(fetchPersistedOrder.rejected, (state, action) => {
            console.error("Persisted Order Endpoint Error", action.error);
            state.order.persistedOrderStatus = "failed";
            state.order.error = action.error.message;
        });
    },
});

export default orderSlice.reducer;

export const {
    setUrlId,
    setCreditCardResponseData,
    retryOrderFetch,
    setTsOrderIsValid,
    setThdOrderIsValid,
    setOrangePayError,
    setCreditCardTransactionSuccess,
    setCreditCardResponseStatus,
    setIsPartialPay,
} = orderSlice.actions;

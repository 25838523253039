import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const selectTheme = (state:RootState) => state.theme.theme;

export const selectTsAccessToken = (state: RootState) => state.user.user.tsAccessToken;
export const selectTsAccessTokenStatus = (state: RootState) => state.user.user.tsAccessTokenStatus;

export const selectThdAuthToken = (state: RootState) => state.user.user.thdAuthToken;
export const selectThdAuthTokenStatus = (state: RootState) => state.user.user.thdAuthTokenStatus;

export const selectPaymentFormLoaded = (state: RootState) => state.user.user.paymentFormLoaded
export const selectPaymentFormSubmitted = (state: RootState) => state.user.user.paymentFormSubmitted
export const selectPaymentFormSubmitStatus = (state: RootState) => state.user.user.paymentFormSubmitStatus
export const selectUser = (state: RootState) => state.user.user
export const selectIsDev = (state: RootState) => state.user.user.isDev

export const selectOrangePayInfo = (state: RootState) => state.user.user.orangePayInfo
export const selectUseDefaultValues = (state: RootState) => state.user.user.useDefaultValues
export const selectIsOrangePayPropsSubmitted = (state: RootState) => state.user.user.isOrangePayPropsSubmitted



export const selectComOrderNumber = (state: RootState) => state.order.order.tsOrder?.thdComOrderNumberC
export const selectOrderId = (state: RootState) => state.user.user.orderId;
export const selectOrder = (state: RootState) => state.order.order
export const selectTsOrder = (state: RootState) => state.order.order.tsOrder
export const selectTsOrderStatus = (state: RootState) => state.order.order.tsOrderStatus
export const selectOrderError = (state: RootState) => state.order.order.error
export const selectTsOrderValid = (state: RootState) => state.order.order.tsOrderIsValid

export const selectOrderLineItems = (state: RootState) => state.order.order.lineItems


export const selectCreditCardResponseStatus = (state: RootState) => state.order.order.creditCardResponseStatus
export const selectCreditCardResponseData = (state: RootState) => state.order.order.creditCardResponseData
export const selectCardDetails = (state: RootState) => state.order.order.creditCardResponseData!.credit.cardDetails
export const selectAuthorizationDetails = (state: RootState) => state.order.order.creditCardResponseData!.credit.authorizationDetails
export const selectCreditCardTransactionSuccess = (state: RootState) => state.order.order.creditCardTransactionSuccess

export const selectThdOrderStatus = (state: RootState) => state.order.order.thdOrderStatus
export const selectThdOrder = (state: RootState) => state.order.order.thdOrder
export const selectParsedThdOrder = (state: RootState) => state.order.order.thdOrder?.parsedResponseResult.validatedObject
export const selectThdOrderValid = (state: RootState) => state.order.order.thdOrderIsValid
export const selectOrangePayError = (state: RootState) => state.order.order.orangePayError


export const selectPostSaleRecallStatus = (state: RootState) => state.order.order.postSaleRecallRequestStatus


export const selectPersistedOrderStatus = (state: RootState) => state.order.order.thdOrderStatus
export const selectPersistedOrderData = (state: RootState) => state.order.order.thdOrder
export const selectIsPartialPayment = (state: RootState) => false


export const selectMessageBody = (state: RootState) => state.notifier.notifierConfig.messageBody;
export const selectMessageHeader = (state: RootState) => state.notifier.notifierConfig.messageHeader;
export const selectOpen = (state: RootState) => state.notifier.notifierConfig.isOpen;
export const isError = (state: RootState) => state.notifier.notifierConfig.isError;
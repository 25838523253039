import { useAppSelector, selectTsOrder, selectPersistedOrderData, selectIsPartialPayment } from "../../store/hooks";
import { Card } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const OrderSummaryDisplay = () => {
    const tsOrder = useAppSelector(selectTsOrder);
    const persistedOrderData = useAppSelector(selectPersistedOrderData);

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    console.log({ persistedOrderData });
    const stagedAmount = Number.parseFloat(persistedOrderData!.stagedAmount);
    const actualSubtotal = Number.parseFloat(persistedOrderData!.subtotalAmount);

    const isPartialPayment = stagedAmount < actualSubtotal;

    const subTotalToDisplay = formatter.format(stagedAmount);
    const taxAmount = Number.parseFloat(persistedOrderData!.taxAmount);
    const tax = formatter.format(taxAmount!);
    const total = stagedAmount + taxAmount;
    const formattedTotal = formatter.format(total);

    return (
        <Card className="mb-2">
            <Card.Header as="h3">Order Summary</Card.Header>
            <Card.Body>
                <Card.Title as="h4">{tsOrder!.buildingTypeC}</Card.Title>
                <Card.Text></Card.Text>
                <Table size="sm">
                    <thead>
                        <tr>
                            <td>Subtotal</td>
                            <td>{subTotalToDisplay}</td>
                        </tr>
                        <tr>
                            <td>Tax</td>
                            <td>{tax}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={isPartialPayment ? "" : "table-success"}>
                            <td>
                                <b>Total</b>
                            </td>
                            <td>
                                <b>{formattedTotal}</b>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                {isPartialPayment && (
                    <div className="partialPayDisclaimer">
                        <p>
                            Please note that because your delivery address is located in a state that regulates payment in full at the time of purchase, your
                            order will not be paid in full by submitting today's payment.
                        </p>
                        <p>
                            You are responsible for the entire purchase amount and subsequent payments will be due at times and amounts as permitted by the laws
                            in your state. You will receive an email from Tuff Shed confirming your order, but you will need to visit homedepot.com to view your
                            receipt. Click{" "}
                            <a href="https://www.888tuffshed.com/tuff-shed-invoice-from-homedepot-com/" target="_blank" rel="noreferrer">
                                here
                            </a>{" "}
                            to see instructions for viewing your balance due at homedepot.com.
                        </p>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default OrderSummaryDisplay;

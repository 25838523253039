import { useEffect } from "react";
import {
  useAppSelector,
  useAppDispatch,
  selectTsAccessToken,
  selectTsAccessTokenStatus,
  selectThdAuthTokenStatus,
  selectTsOrderStatus,
  selectOrderId,
  selectThdOrderStatus,
  selectThdAuthToken,
  selectComOrderNumber,
  selectIsDev,
  selectOrderError,
  selectThdOrderValid,
  selectTsOrderValid,
  selectTsOrder,
  selectThdOrder,
  selectOpen,
  selectPersistedOrderStatus,
  selectPersistedOrderData,
  selectIsPartialPayment,
} from "../../store/hooks";
import { useRouteMatch } from "react-router-dom";
import {
  fetchTsOrder,
  FetchValues,
  fetchThdOrder,
  ThdFetchValues,
  setTsOrderIsValid,
  setThdOrderIsValid,
  fetchPersistedOrder,
} from "../../store/orderSlice";
import { setOrderId } from "../../store/userSlice";
import { setError, setNotification } from "../../store/notifierSlice";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";

import OrderDetailsDisplay from "./OrderDetailsDisplay";
import OrderAddressesDisplay from "./OrderAddressesDisplay";
import OrderSummaryDisplay from "../infoDisplays/OrderSummaryDisplay";
import OrangePayDevWrapper from "../wrappers/OrangePayDevWrapper";
import PartialPaymentDisplay from "./PartialPaymentDisplay";

const OrderInfoDisplay = () => {
  const dispatch = useAppDispatch();
  const thdTokenStatus = useAppSelector(selectThdAuthTokenStatus);
  const tsTokenStatus = useAppSelector(selectTsAccessTokenStatus);
  const tsAccessToken = useAppSelector(selectTsAccessToken);
  const tsToken = tsAccessToken?.accessToken;
  const tsOrderStatus = useAppSelector(selectTsOrderStatus);
  const thdOrderStatus = useAppSelector(selectThdOrderStatus);
  const orderId = useAppSelector(selectOrderId);
  const comOrderNumber = useAppSelector(selectComOrderNumber);
  const tsOrder = useAppSelector(selectTsOrder);
  const thdOrderValid = useAppSelector(selectThdOrderValid);
  const tsOrderValid = useAppSelector(selectTsOrderValid);
  const thdOrder = useAppSelector(selectThdOrder);
  const isOpen = useAppSelector(selectOpen);
  const isDev = useAppSelector(selectIsDev);
  const persistedOrderStatus = useAppSelector(selectPersistedOrderStatus);
  const persistedOrderData = useAppSelector(selectPersistedOrderData);
  const isPartialPayment = useAppSelector(selectIsPartialPayment);

  const getOrderError = useAppSelector(selectOrderError);

  let match: any = useRouteMatch("/order/:id");
  const urlParamId = match.params.id || null;

  //oriderId is set in the user slice and recalled from there but this is
  //an aritfact from when this app shared that data between slices.  Can
  //probably just live in the order slice only

  useEffect(() => {
    //validation for the order Info fetch, the first call in the chain. We can add more fields but
    //these three are the most crucial, and should indicate if an order is legit.
    const validateTsOrder = () => {
      console.log({ tsOrder });
      if (
        tsOrder!.type === "Home Depot" &&
        tsOrder!.thdComOrderNumberC != null &&
        tsOrder!.status === "Draft"
      ) {
        dispatch(setTsOrderIsValid({ isValid: true, reason: null }));
      } else if (
        tsOrder!.type === "Home Depot" &&
        tsOrder!.thdComOrderNumberC != null &&
        tsOrder!.status !== "Draft"
      ) {
        const messageHeader = "Order Status";
        const messageBody =
          "Your order is not in a state that is ready to accept payment.";
        dispatch(setNotification({ messageBody, messageHeader }));
        dispatch(setTsOrderIsValid({ isValid: false, reason: "Order State" }));
      } else {
        const messageHeader = "Invalid Order";
        const messageBody = "The order number you entered is not valid.";
        dispatch(setNotification({ messageBody, messageHeader }));
        dispatch(setTsOrderIsValid({ isValid: false, reason: "Invalid" }));
      }
    };

    //validate the thdOrder
    const validateThdOrder = () => {
      console.log({ thdOrder });
      if (
        thdOrder!.statusMessage === "OK" &&
        thdOrder!.storeNumber != null &&
        thdOrder!.wasSuccessful === true
      ) {
        dispatch(setThdOrderIsValid({ isValid: true, reason: null }));
      } else {
        dispatch(setThdOrderIsValid({ isValid: false, reason: "Invalid" }));
        const messageHeader = "Order Status";
        const messageBody =
          "Your order is not in a state that is ready to accept payment.";
        dispatch(setNotification({ messageBody, messageHeader }));
      }
    };

    //if the tokens are good and orderId is still null then set the orderID and start the chain of API calls/ validations
    if (
      tsTokenStatus === "succeeded" &&
      thdTokenStatus === "succeeded" &&
      tsOrderStatus === "idle" &&
      thdOrderStatus === "idle" &&
      !orderId
    ) {
      dispatch(setOrderId(urlParamId));
    }

    //fetch the order Info (salesforce record).
    if (
      !!orderId &&
      tsTokenStatus === "succeeded" &&
      tsOrderStatus === "idle" &&
      thdTokenStatus === "succeeded" &&
      thdOrderStatus === "idle"
    ) {
      const orderValues: FetchValues = {
        AccessToken: tsToken!,
        urlParamId: orderId!,
      };
      dispatch(fetchTsOrder(orderValues));
    }

    if (
      tsOrderStatus === "succeeded" &&
      tsOrderValid.isValid === null &&
      thdTokenStatus === "succeeded" &&
      thdOrderStatus === "idle"
    ) {
      console.log("validate tsOrder ");
      validateTsOrder();
    }

    //fetch hdOrder if the first call is successful and passes validation
    if (
      tsOrderStatus === "succeeded" &&
      tsOrderValid.isValid === true &&
      tsOrderValid.reason === null &&
      thdTokenStatus === "succeeded" &&
      thdOrderStatus === "idle" &&
      thdOrderValid.isValid === null
    ) {
      const thdFetchValues: ThdFetchValues = {
        AccessToken: tsToken!,
        comOrderNumber: comOrderNumber!,

        // comOrderNumber: isDev ? 'H9759-31595' : comOrderNumber!
        //H9749-16200
      };
      dispatch(fetchThdOrder(thdFetchValues));
    }

    //run validation on ThdOrder

    if (
      tsOrderStatus === "succeeded" &&
      thdOrderStatus === "succeeded" &&
      tsOrderValid.isValid === true &&
      thdOrderValid.isValid === null
    ) {
      console.log("validate Thd order info");
      ///actually validate the ts order
      validateThdOrder();
    }

    //add if to run the persisted order after HdOrder is validated

    if (thdOrderValid.isValid === true && persistedOrderStatus === "idle") {
      const persistedOrderFetchValues = {
        AccessToken: tsToken,
        comOrderNumber: comOrderNumber!,

        //these are here for ease of testing
        // AccessToken: devClusterToken,
        // comOrderNumber: "H2643-0001"
        //H2643-0001 with partial Pay
        //"H6074-0001" without partial pay
      };
      dispatch(fetchPersistedOrder(persistedOrderFetchValues));
    }

    //error handling

    if (
      thdOrderStatus === "failed" ||
      tsOrderStatus === "failed" ||
      persistedOrderStatus === "failed"
    ) {
      const messageBody = "Please wait a moment and refresh your browser";
      const messageHeader = "Something Went Wrong...";
      dispatch(setError({ messageBody, messageHeader }));
      console.log(getOrderError);
    }
  });

  if (
    // hdOrderValid.isValid === null &&
    //l
    persistedOrderStatus !== "succeeded" &&
    isOpen === false
  ) {
    return (
      <Container>
        <Row>
          <Col>
            <>
              <Spinner
                animation="grow"
                variant="secondary"
                className="mx-auto"
              />
              <h2> Loading Order</h2>
            </>
          </Col>
        </Row>
      </Container>
    );
  } else if (
    thdOrderValid.isValid === true &&
    tsOrderValid.isValid === true &&
    persistedOrderStatus === "succeeded"
  ) {
    return (
      <Container>
        <Row>
          <Col lg={4} md={5}>
            <div className="summaryIframeContainer">
              <OrderSummaryDisplay />
              {isPartialPayment ? <PartialPaymentDisplay /> : null}
              <OrangePayDevWrapper />
            </div>
          </Col>
          <Col lg={8} md={7}>
            <OrderAddressesDisplay />
            <OrderDetailsDisplay />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return <></>;
  }
};

export default OrderInfoDisplay;

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store/store";
import { initializeAppInsights } from "./utils/AppInsights";

// import "bootstrap/dist/css/bootstrap.min.css";

export const ORANGE_PAY_URL = "https://orangepay-ecommerce.hdpayments.homedepot.com/"

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

initializeAppInsights();

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ORANGE_PAY_URL } from "..";

const tsAccessTokenEndpoint = process.env.REACT_APP_TS_ACCESS_TOKEN_API_ENDPOINT;
const thdAuthTokenEndpoint = process.env.REACT_APP_HD_AUTH_TOKEN_API_ENDPOINT;

export interface User {
  tsAccessTokenStatus: string;
  loginRequired?: boolean;
  userName?: string;
  deliveryZip?: string;
  language: "english" | "spanish";
  tsAccessToken?: AccessToken | null;
  error?: string | null;
  orderId?: string | null;
  paymentFormLoaded: boolean;
  paymentFormSubmitted: boolean;
  thdAuthToken?: AccessToken | null;
  thdAuthTokenStatus: string;
  paymentFormSubmitStatus: string;
  environment?: string;
  isDev?: boolean;
  orangePayInfo: OrangePayInfo;
  isOrangePayPropsSubmitted: boolean;
  useDefaultValues: boolean;
}

export interface OrangePayInfo {
  env?: string;
  clientId?: string;
  clientToken?: string | null;
  storeNumber: string | null;
  storeZipCode: string;
  amount: number;
  countryCode?: string;
  languageCode?: string;
  orderNumber: string;
  uri: string;

  transactionOptions?: {
    preAuthorize: boolean;
  };

  billingAddress: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
  };
}

export const defaultOrangePayInfo = {
  env: "PROD",
  clientId: "TUFFSHED",
  clientToken: null,
  storeNumber: null,
  storeZipCode: "30080",
  amount: 3400.0,
  countryCode: "US",
  languageCode: "en-US",
  orderNumber: "H9749-16200",
  uri: ORANGE_PAY_URL,
  transactionOptions: {
    preAuthorize: false,
  },

  billingAddress: {
    addressLine1: "2455 Paces Ferry Rd",
    addressLine2: "Building D",
    city: "Atlanta",
    state: "GA",
    zipCode: "30339",
  },
};

export interface AccessToken {
  token_type?: "Bearer";
  expires_in?: number;
  ext_expires_in?: number;
  accessToken?: string | null;
  access_token?: string | null;
}

export interface ThdAuthToken {
  access_token: string | null;
  token_type: "Bearer";
  expires_in: number;
  scope: string;
}

export const defaultThdAuthToken: ThdAuthToken = {
  token_type: "Bearer",
  expires_in: 3559,
  access_token: null,
  scope: "orangePayServices.all",
};

export const defaultAccessToken: AccessToken = {
  token_type: "Bearer",
  expires_in: 3559,
  ext_expires_in: 3559,
  accessToken: null,
};

export const defaultUser: User = {
  tsAccessTokenStatus: "idle",
  loginRequired: false,
  userName: "TuffShed Dev",
  deliveryZip: "19125",
  tsAccessToken: defaultAccessToken,
  error: null,
  language: "english",
  orderId: null,
  paymentFormLoaded: false,
  paymentFormSubmitted: false,
  thdAuthToken: defaultThdAuthToken,
  thdAuthTokenStatus: "idle",
  paymentFormSubmitStatus: "idle",
  environment: "dev",
  isDev: false,
  orangePayInfo: defaultOrangePayInfo,
  isOrangePayPropsSubmitted: false,
  useDefaultValues: false,
};

//Defining our initialState's type
type initialStateType = {
  user: User;
};

const initialState: initialStateType = {
  user: defaultUser,
};

export const getTsAccessToken = createAsyncThunk(
  "user/getTsAccessToken",
  async () => {
    const response = await axios.get<AccessToken>(`${tsAccessTokenEndpoint}`);
    const token: AccessToken = await response.data;
    return token;
  }
);

export const getThdAuthToken = createAsyncThunk(
  "user/getThdAuthToken",
  async () => {
    const response = await axios.get<ThdAuthToken>(`${thdAuthTokenEndpoint}`);
    const thdAuthToken: ThdAuthToken = await response.data;
    return thdAuthToken;
  }
);

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const submitOrangePayForm = createAsyncThunk(
  "user/paymentFormSubmitStatus",
  async () => {
    // setTimeout("alert('this alert is timedout and should be the first');", 5000);

    await sleep(5000);
    // alert('this should be the second one');
  }
);


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setTsAccessToken(state, action) {},
    setOrderId(state, action) {
      state.user.orderId = action.payload;
    },
    setPaymentFormLoaded(state, action) {
      state.user.paymentFormLoaded = action.payload;
    },
    setPaymentFormSubmitted(state, action) {
      state.user.paymentFormSubmitted = action.payload;
    },
    setPaymentFormSubmitStatus(state, action) {
      state.user.paymentFormSubmitStatus = action.payload;
    },
    setOrangePayInfo(state, action) {
      state.user.orangePayInfo = {
        ...state.user.orangePayInfo,
        ...action.payload,
      };
    },
    submitOrangePayProps(state, action) {
      state.user.isOrangePayPropsSubmitted = action.payload;
    },
    setOrangePayInfoToTestData(state, action) {
      state.user.orangePayInfo = defaultOrangePayInfo;
      // state.user.isOrangePayPropsSubmitted = true
      // console.log(action.payload)
    },
    toggleUseDefaultValues(state, action) {
      state.user.useDefaultValues = action.payload;
      // state.user.isOrangePayPropsSubmitted = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTsAccessToken.pending, (state, action) => {
      state.user.tsAccessTokenStatus = "loading";
    });
    builder.addCase(getTsAccessToken.fulfilled, (state, action) => {
      state.user.tsAccessTokenStatus = "succeeded";
      state.user.tsAccessToken = action.payload;
    });
    builder.addCase(getTsAccessToken.rejected, (state, action) => {
      state.user.tsAccessTokenStatus = "failed";
      state.user.error = action.error.message;
    });
    builder.addCase(getThdAuthToken.pending, (state, action) => {
      state.user.thdAuthTokenStatus = "loading";
    });
    builder.addCase(getThdAuthToken.fulfilled, (state, action) => {
      state.user.thdAuthTokenStatus = "succeeded";
      state.user.thdAuthToken = action.payload;
    });
    builder.addCase(getThdAuthToken.rejected, (state, action) => {
      state.user.thdAuthTokenStatus = "failed";
      state.user.error = action.error.message;
    });
    builder.addCase(submitOrangePayForm.pending, (state, action) => {
      state.user.paymentFormSubmitStatus = "loading";
    });
    builder.addCase(submitOrangePayForm.fulfilled, (state, action) => {
      state.user.paymentFormSubmitStatus = "succeeded";
      // state.user.paymentFormSubmitStatus= action.payload
    });
    builder.addCase(submitOrangePayForm.rejected, (state, action) => {
      state.user.paymentFormSubmitStatus = "failed";
      state.user.error = action.error.message;
    });
  },
});

export default userSlice.reducer;

export const {
  setTsAccessToken,
  setOrderId,
  setPaymentFormLoaded,
  setPaymentFormSubmitted,
  setPaymentFormSubmitStatus,
  setOrangePayInfo,
  setOrangePayInfoToTestData,
  toggleUseDefaultValues,
  submitOrangePayProps,
} = userSlice.actions;

import React, { useEffect } from "react";

import {
  useAppSelector,
  useAppDispatch,
  selectOrangePayInfo,
  selectTsOrder,
  selectOrangePayError,
  selectThdAuthToken,
  selectPersistedOrderData,
} from "../../store/hooks";
import { setOrangePayInfo, OrangePayInfo } from "../../store/userSlice";

import { PaymentForm } from "../forms/PaymentForm";
import { ORANGE_PAY_URL } from "../..";

const PaymentFormWrapper = () => {
  const dispatch = useAppDispatch();
  const thdAuthToken = useAppSelector(selectThdAuthToken);
  const tsOrder = useAppSelector(selectTsOrder);
  const orangePayInfo = useAppSelector(selectOrangePayInfo);
  const orangePayError = useAppSelector(selectOrangePayError);
  const persistedOrderData = useAppSelector(selectPersistedOrderData);

  ///set error if these values can't be set

  //Look into 2 line addresses

  useEffect(() => {
    const orangePayInfoFromOrder: OrangePayInfo = {
      clientId: "TUFFSHED",
      clientToken: thdAuthToken?.access_token!,
      storeNumber: tsOrder!.salesLocation!.hdStoreNumberC.toString(),
      storeZipCode: tsOrder!.salesLocation!.billingPostalCode.toString(),
      amount: Number.parseFloat(persistedOrderData!.totalAmount),
      orderNumber: tsOrder!.thdComOrderNumberC,
      uri: ORANGE_PAY_URL,
      billingAddress: {
        addressLine1: tsOrder!.personAccount.billingStreet,
        addressLine2: "",
        city: tsOrder!.personAccount.billingCity,
        state: tsOrder!.personAccount.billingState,
        zipCode: tsOrder!.personAccount.billingPostalCode.toString(),
      },
    };

    if (orangePayInfo.storeNumber === null) {
      dispatch(setOrangePayInfo(orangePayInfoFromOrder));
    }
  }, []);
  if (orangePayError === null && orangePayInfo.storeNumber !== null) {
    return (
      <>
        <div id="iFrameContainer">
          <PaymentForm />
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default PaymentFormWrapper;

import Card from "react-bootstrap/Card";
import { useAppSelector, selectTsOrder } from "../../store/hooks";
import OrderDisplayTable from "../tables/OrderDisplayTable";

const OrderDetailsDisplay = () => {
    const tsOrder = useAppSelector(selectTsOrder);
    return (
        <Card>
            <Card.Header as="h3">Order Details</Card.Header>
            <Card.Body>
                <Card.Title className="mb-10" as="h4">
                    <div>{tsOrder!.buildingTypeC}</div>
                    <div style={{ marginTop: "10px", fontSize: "16px", fontWeight: "normal" }}>
                        <strong>Order Number</strong>: {tsOrder!.thdComOrderNumberC}
                    </div>
                </Card.Title>
                <OrderDisplayTable />
            </Card.Body>
        </Card>
    );
};

export default OrderDetailsDisplay;

import {
    combineReducers,
    configureStore,
  } from "@reduxjs/toolkit";
  
  import themeReducer from "./themeSlice";
  import userReducer from './userSlice';
  import orderReducer from './orderSlice'
  import notifierSlice from "./notifierSlice";
  
  const store = configureStore({
    reducer: combineReducers({
      theme: themeReducer,
      user: userReducer,
      order: orderReducer,
      notifier: notifierSlice
    }),
    devTools: true,
  });
  
  export default store;
  
  export type RootState = ReturnType<typeof store.getState>; // A global type to access reducers types
  export type AppDispatch = typeof store.dispatch; // Type to access dispatch
  
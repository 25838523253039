import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  useAppSelector,
  useAppDispatch,
  selectOpen,
  selectMessageBody,
  selectMessageHeader,
  selectOrderError,
  isError,
  selectTsOrderValid,
} from "../../store/hooks";
import { retryOrderFetch } from "../../store/orderSlice";
import { hideNotifier } from "../../store/notifierSlice";

const Notifier = () => {
  const hideModal = useAppSelector(hideNotifier);
  const orderError = useAppSelector(selectOrderError);
  const clearOrderErrors = useAppSelector(retryOrderFetch);
  const isOpen = useAppSelector(selectOpen);
  const iserror = useAppSelector(isError);
  const messageBody = useAppSelector(selectMessageBody);
  const messageHeader = useAppSelector(selectMessageHeader);
  const dispatch = useAppDispatch();
  const tsOrderValid = useAppSelector(selectTsOrderValid);
  const homeLink = process.env.REACT_APP_SALES_CHANNEL_HOME;

  const handleClose = () => {
    dispatch(hideModal);

    // if (!!orderError) {
    //   // dispatch(clearOrderErrors)
    //   dispatch(hideModal)
    //   console.log('selectOrderError', selectOrderError)

    // } else if
    //   (
    //     tsOrderValid.isValid === false

    //   ) {
    //    window.location.assign(`${homeLink}`)
    // }
    // else {
    //   dispatch(hideModal)

    // }
  };

  useEffect(() => {
    console.log("orderError", orderError);
  }, [isOpen]);

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="alert-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {messageHeader}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{messageBody}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ display: iserror ? "block" : "none" }}
          onClick={() => window.location.reload()}
          variant="hd-primary"
          autoFocus
          size="lg"
          className="btn-return-home"
        >
          Refresh Page
        </Button>
        <Button
          autoFocus
          onClick={handleClose}
          variant="hd-primary"
          size="lg"
          className="btn-return-home"
        >
          close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Notifier;

import { useHistory, useRouteMatch } from "react-router-dom";
import { useEffect } from "react";
import OrderInfoDisplay from "../infoDisplays/OrderInfoDisplay";
import OrderConfirmation from "../infoDisplays/OrderConfirmation";
import OrderIdNotValid from "../../pages/OrderIdNotValid";
import {
  useAppSelector,
  selectPaymentFormSubmitStatus,
  selectCreditCardResponseStatus,
} from "../../store/hooks";

//this validation should be handled a little better and this functionality should probably
// a switch at app level state that changes conditional rendering in the router
//check status of the thdOrder Jawn and change content to confirmation based on it.

const OrderWrapper = () => {
  const history = useHistory();

  const paymentFromSubmitStatus = useAppSelector(selectPaymentFormSubmitStatus);

  const creditCardResponseStatus = useAppSelector(
    selectCreditCardResponseStatus
  );
  let match: any = useRouteMatch("/order/:id");
  const urlParamId = match.params.id || null;
  let content;
  console.log('urlParamId', urlParamId)

  useEffect(() => {}, [creditCardResponseStatus]);

  if (
    urlParamId.length === 18 &&
    paymentFromSubmitStatus !== 'succeeded'
    //once this status changes to loading, we know Orange pay has successfully process the transaction
  ) {
    console.log("you have entered a valid order id!");
    content = <OrderInfoDisplay />;

  } else if (urlParamId.length === 18 
    && paymentFromSubmitStatus === 'succeeded'
    ) {
    history.push(`/confirmation/${urlParamId}`);
    content = <OrderConfirmation />;
  }
  // maybe put another elseif and a spinner?
  else {
    console.log("the order id you entered is not valid!");
    content = <OrderIdNotValid />;
  }

  return (
    <>
      <div className="display-box">{content}</div>
    </>
  );
};

export default OrderWrapper;

import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import tsLogo from "../../assets/logo-hor.png";
import hdLogo from "../../assets/hd-logo.png";

import { useAppSelector, useAppDispatch } from "../../store/hooks";

import { applyTheme, selectTheme, Theme } from "../../store/themeSlice";

const NavBar = () => {
    const theme = useAppSelector(selectTheme);
    const dispatch = useAppDispatch();

    const changeTheme = (theme: Theme) => {
        dispatch(applyTheme(theme));
    };

    return (
        <Navbar expand="lg" bg="light" variant="light" collapseOnSelect className="shadow p-3 mb-3">
            <Container fluid>
                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                <Navbar.Brand>
                    <img alt="Tuff Shed Logo" src={tsLogo} width="220" height="44" className="d-inline-block align-top" />{" "}
                </Navbar.Brand>
                <Navbar.Brand>
                    <img alt="Home Depot Logo" src={hdLogo} width="50" height="50" className="ml-auto" />
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
};

export default NavBar;

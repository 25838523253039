
const SystemsAreDown = () => {
    return (
        <h2>
            Our systems are currently down. Please try again later.
        </h2>
    )
}

export default SystemsAreDown

import React from 'react'

const OrderIdNotValid = () => {
    return (
        <h2>
            Please enter a valid orderId.
        </h2>
    )
}

export default OrderIdNotValid

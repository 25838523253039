import { useAppSelector, selectTsOrder, selectTsOrderStatus } from "../../store/hooks";
import { CardGroup } from "react-bootstrap";
import { Card } from "react-bootstrap";

const OrderAddressesDisplay = () => {
    const tsOrderStatus = useAppSelector(selectTsOrderStatus);
    const tsOrder = useAppSelector(selectTsOrder);

    if (tsOrderStatus !== "succeeded") {
        return null;
    } else {
        return (
            <CardGroup className="mb-4">
                <Card>
                    <Card.Header as="h4">Billing Address</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {tsOrder!.personAccount.name}
                            <br />
                            {tsOrder!.personAccount.billingStreet}
                            <br />
                            {tsOrder!.personAccount.billingCity}, {tsOrder!.personAccount.billingState}
                            <br />
                            {tsOrder!.personAccount.billingPostalCode}
                            <br />
                            {tsOrder!.personAccount.phone}
                            <br />
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header as="h4">Shipping Address</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {tsOrder!.personAccount.name}
                            <br />
                            {tsOrder!.shippingStreet}
                            <br />
                            {tsOrder!.shippingCity}, {tsOrder!.shippingState}
                            <br />
                            {tsOrder!.shippingPostalCode}
                            <br />
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header as="h4">Home Depot Store</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            Store #{tsOrder!.salesLocation!.hdStoreNumberC} <br />
                            {tsOrder!.salesLocation!.billingStreet}
                            <br />
                            {tsOrder!.salesLocation!.billingCity}, {tsOrder!.salesLocation!.billingState} <br />
                            {tsOrder!.salesLocation!.billingPostalCode}
                            <br />
                            {tsOrder!.salesLocation!.phone}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
        );
    }
};

export default OrderAddressesDisplay;

import { Container, Row, Col } from "react-bootstrap";
import React, { useEffect } from "react";

import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import {
    useAppDispatch,
    useAppSelector,
    selectPaymentFormLoaded,
    selectPaymentFormSubmitted,
    selectThdAuthToken,
    selectTsAccessToken,
    selectTsOrder,
    selectOrangePayInfo,
    selectCreditCardResponseStatus,
    selectCreditCardResponseData,
    selectThdOrderStatus,
    selectThdOrder,
    selectParsedThdOrder,
    selectComOrderNumber,
    selectOrderId,
    selectPaymentFormSubmitStatus,
    selectThdOrderValid,
    selectTsOrderValid,
    selectOrangePayError,
    selectCreditCardTransactionSuccess,
    selectPostSaleRecallStatus,
} from "../../store/hooks";
import { PostSaleRecallRequestData, ThdOrderValidatedObject, fetchPostSaleRecall } from "../../store/orderSlice";

const OrderConfirmation = () => {
    const dispatch = useAppDispatch();
    const paymentFormLoaded = useAppSelector(selectPaymentFormLoaded);
    const paymentFormSubmitted = useAppSelector(selectPaymentFormSubmitted);
    const paymentFormSubmitStatus = useAppSelector(selectPaymentFormSubmitStatus);
    const thdAuthToken = useAppSelector(selectThdAuthToken);
    const thdAccessToken = thdAuthToken!.access_token;
    const tsAccessToken = useAppSelector(selectTsAccessToken);
    const tsToken = tsAccessToken?.accessToken;
    const tsOrder = useAppSelector(selectTsOrder);
    const orangePayInfo = useAppSelector(selectOrangePayInfo);
    const creditCardResponseStatus: boolean = useAppSelector(selectCreditCardResponseStatus);
    const ccSuccess = useAppSelector(selectCreditCardTransactionSuccess);
    const thdOrder = useAppSelector(selectThdOrder);
    const parsedThdOrderResponse: ThdOrderValidatedObject | undefined = useAppSelector(selectParsedThdOrder);
    const thdOrderStatus = useAppSelector(selectThdOrderStatus);
    const comOrderNumber = useAppSelector(selectComOrderNumber);
    const salesforceOrderId = useAppSelector(selectOrderId);
    const creditCardResponseData = useAppSelector(selectCreditCardResponseData);
    const orangePayError = useAppSelector(selectOrangePayError);
    const postSaleRecallStatus = useAppSelector(selectPostSaleRecallStatus);

    const thdOrderValid = useAppSelector(selectThdOrderValid);
    const tsOrderValid = useAppSelector(selectTsOrderValid);
    const environment = process.env.REACT_APP_ENVIRONMENT;

    const history = useHistory();
    const homeLink = process.env.REACT_APP_SALES_CHANNEL_HOME;

    const handleClick = () => {
        history.replace(`${homeLink}`);
    };

    const postSaleRecallRequestData: PostSaleRecallRequestData = {
        retailStoreId: thdOrder!.storeNumber,
        customerAddressCity: parsedThdOrderResponse?.customer?.address?.city,
        customerAddressState: parsedThdOrderResponse?.customer?.address?.state,
        customerAddressPostalCode: parsedThdOrderResponse?.customer?.address?.postalCode,
        customerNameEmail: tsOrder!.personAccount!.personEmail,
        lineItemAmount: orangePayInfo!.amount.toString(),
        lineItemAuthUuid: creditCardResponseData!.credit!.authorizationDetails.auth_uuid,
        authorizationCode: creditCardResponseData!.credit!.authorizationDetails.authorizationIdentificationResponse,
        referenceNumber: creditCardResponseData!.credit!.cardDetails.accountNumberXref,
        poNumber: "SHEDS",
        cardProcessorId: creditCardResponseData!.credit.authorizationDetails.cardProcessorID,
        cardType: "Credit",
        tenderType: "creditDebit",
        expirationDate: creditCardResponseData!.credit.cardDetails.cardExpirationDate,
        comOrderNumber: orangePayInfo.orderNumber,
        salesforceOrderId: salesforceOrderId!,
        //do we need these last few?
        systemSourceUserId: "PR7MM09",
        entryMethod: "KEYED",
        typeCode: "Sale",
        xrefNumber: creditCardResponseData!.credit.cardDetails.accountNumberXref,
        paymentMethodCode: creditCardResponseData!.credit.cardDetails.paymentMethodCode,
        authorizationStatus: creditCardResponseData!.credit.authorizationDetails.authorizationStatus,
    };

    console.log("postSaleRecallRequestData", postSaleRecallRequestData);

    useEffect(() => {
        if (postSaleRecallStatus === "idle") {
            // setModalShow(false)

            console.log("DOING THE POST SALE RECALL --- postSaleRecallRequestData", postSaleRecallRequestData);
            dispatch(
                fetchPostSaleRecall({
                    data: postSaleRecallRequestData,
                    apiAccessToken: tsToken!,
                })
            );
        }

        // if (postSaleRecallStatus === "failed") {
        //   console.log("postSaleRecallStatus", postSaleRecallStatus);

        // setModalShow(false);
        // const messageHeader = "Error";
        // const messageBody = "Your payment cannot be processed";
        // dispatch(setNotification({ messageBody, messageHeader }));
    });
    if (postSaleRecallStatus === "succeeded") {
        return (
            <Container className="mx-auto">
                <Row>
                    <Col>
                        <Container className="confirmationPage-Container">
                            <div className="confirmationPage-Title">
                                <p>Thank you for your order!</p>
                            </div>
                            <div className="confirmationPage-Text">
                                <p>Your Home Depot Order Number is: {orangePayInfo.orderNumber}.</p>
                                <p>
                                    You will be receiving follow up communications regarding your building via email, including an outline of the Tuff Shed
                                    process for turning your order into a great building.
                                </p>
                                <p>
                                    We will use the contact information that you included in this order. If you do not receive this email, please contact us at
                                    &nbsp;
                                    <a href="mailto: homedepotsales@tuffshed.com">homedepotsales@tuffshed.com</a>.
                                </p>
                                <p>
                                    Thank You,
                                    <br />
                                    Tuff Shed, Inc.
                                </p>
                            </div>
                            <a href={homeLink}>
                                <Button variant="hd-primary" size="lg" className="btn-return-home" onClick={handleClick}>
                                    Return to 888tuffshed.com
                                </Button>
                            </a>
                        </Container>
                    </Col>
                </Row>
            </Container>
        );
    } else if (postSaleRecallStatus === "failed") {
        return (
            <Container className="mx-auto">
                <Row>
                    <Col>
                        <Container className="confirmationPage-Container">
                            <div className="confirmationPage-Title">
                                <p>Error processing your order ({orangePayInfo.orderNumber})</p>
                            </div>
                            <div className="confirmationPage-Text">
                                <p>
                                    An error occurred. Please contact us at <strong>1-888-TUFF SHED (883-3743)</strong>.
                                </p>
                                <br />
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>
        );
    } else {
        return (
            <Container className="mx-auto">
                <Row>
                    <Col>
                        <Container className="confirmationPage-Container">
                            <div className="confirmationPage-Title">
                                <p>Completing Order...</p>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>
        );
    }
};

export default OrderConfirmation;

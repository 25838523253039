import { useEffect } from 'react'
import { ThemeProvider } from "styled-components";
import { useAppSelector, useAppDispatch } from "./store/hooks";
import "./styling/custom.scss";
import GlobalStyle from "./styling/GlobalStyle";
import AppRouter from "./components/routing/AppRouter";
import { selectTheme } from "./store/themeSlice";
import { getTsAccessToken, getThdAuthToken  } from './store/userSlice';
import NavBar from './components/navbar/NavBar';
import Footer from './components/footer/Footer'

import  Notifier from './components/modals/Notifier'
// declare const window: any;


function App() {
  const dispatch = useAppDispatch()
  const theme = useAppSelector(selectTheme);


  useEffect(() => {
      dispatch(getTsAccessToken())
      dispatch(getThdAuthToken())
  })
  

  return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
          <Notifier/>
          <NavBar/>
          <AppRouter />
          <Footer />

      </ThemeProvider>
  );
}

export default App;

import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import OrderWrapper from "../wrappers/OrderWrapper";
import OrderConfirmation from "../infoDisplays/OrderConfirmation";
import {
  useAppSelector,
  selectThdAuthTokenStatus,
  selectTsAccessTokenStatus,
} from "../../store/hooks";
import SystemsAreDown from "../../pages/SystemsAreDown";
import ExternalRedirect from "./ExternalRedirect";

export default function AppRouter() {
  const thdTokenStatus = useAppSelector(selectThdAuthTokenStatus);
  const tsTokenStatus = useAppSelector(selectTsAccessTokenStatus);
  return (
    <Router>
      <Suspense fallback={<Spinner animation="grow" variant="primary" />}>
        <main>
          {thdTokenStatus === "failed" || tsTokenStatus === "failed" ? (
            <SystemsAreDown />
          ) : (
            <Switch>
              <Route path="/order/:id" component={OrderWrapper} />
              <Route path="/confirmation/:id" component={OrderConfirmation} />
              <Route path="/order/" component={ExternalRedirect} exact />
              <Route path="/" component={ExternalRedirect} exact />
              <Redirect from="*" to="/" />
            </Switch>
          )}
        </main>
      </Suspense>
    </Router>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export interface MessageConfig {
  isOpen: boolean;
  messageHeader: string | null;
  messageBody: string | null;
  isError: boolean;
}

export const defaultNotifierConfig: MessageConfig = {
  isOpen: false,
  messageHeader: "Something Went Wrong",
  messageBody: "Please wait a moment and refresh your browser",
  isError: true,
};

type initialStateType = {
  notifierConfig: MessageConfig;
};

const initialState: initialStateType = {
  notifierConfig: defaultNotifierConfig,
};

const notifierSlice = createSlice({
  name: "notifier",
  initialState,
  reducers: {
    setError: (state, action) => {
      // state.notifierConfig.messageHeader = "Something Went Wrong"
      state.notifierConfig.messageBody = action.payload.messageBody;
      state.notifierConfig.isOpen = true;
      state.notifierConfig.isError = true;
    },
    hideNotifier: (state, action) => {
      state.notifierConfig.messageHeader = null;
      state.notifierConfig.messageBody = null;
      state.notifierConfig.isOpen = false;
      state.notifierConfig.isError = false;
    },
    openNotifier: (state, action) => {
      state.notifierConfig.messageBody = action.payload.messageBody;
      state.notifierConfig.messageHeader = action.payload.messageHeader;
      state.notifierConfig.isOpen = true;
    },
    setNotification: (state, action) => {
      state.notifierConfig.messageHeader = action.payload.messageHeader;
      state.notifierConfig.messageBody = action.payload.messageBody;
      state.notifierConfig.isOpen = true;
      state.notifierConfig.isError = false;
    },
  },
});

export default notifierSlice.reducer;

export const {
  setError,
  hideNotifier,
  setNotification,
  openNotifier,
} = notifierSlice.actions;
